<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <span
              class="dis-ib ms-50"
              title="Search documents"
              @click="openFilters"
            >
              <a
                class="btn btn-icon btn-primary"
              ><i data-feather="search" /></a>
            </span>
            <a
              class="btn btn-icon btn-secondary ms-1"
              @click="$router.go(-1)"
            ><i data-feather="chevron-left" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div
            v-if="!loading"
            class="col-12"
          >
            <CompleteTable
              :sorted-field="sortedField"
              :data="items"
              :sort-mapping="sortMapping"
              :title="'files'"
              :total="items.length"
              :fetch-path="''"
              :export-path="''"
              :filter-path="''"
              :hide-bottom="true"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="$router.push({name: 'cae.view-document', params: {id: field.id}, query: {host: filters.host.id}})">
                        <i data-feather="eye" />
                        View document
                      </b-dropdown-item>
                      <b-dropdown-item
                        :href="field.url"
                        target="_blank"
                      >
                        <i data-feather="download" />
                        Download document
                      </b-dropdown-item>
                      <!-- <hr>
                  <b-dropdown-item @click="showDeleteModal(field.id)">
                    <i data-feather="trash-2" />
                    Delete
                  </b-dropdown-item> -->
                    </div>
                  </b-dropdown>
                </td>
              </template>
            </CompleteTable>
          </div>
          <div v-else>
            <div class="loading custom-loading">
              <div class="effect-1 effects" />
              <div class="effect-2 effects" />
              <div class="effect-3 effects" />
            </div>
          </div>
        </div>
      </div>
      <Search @appliedFilters="reloadFiles" />
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../partials/components/CompleteTable.vue'
import Search from './partials/Search.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    Search,
  },
  data() {
    return {
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Section',
          checked: true,
          order: 2,
        },
        {
          name: 'Subsection',
          checked: true,
          order: 3,
        },
        {
          name: 'Upload date',
          checked: true,
          order: 4,
        },

        {
          name: 'Deadline',
          checked: true,
          order: 5,
        },
        {
          name: 'Status',
          checked: true,
          order: 6,
        },
      ],
      sortMapping: {
        Name: 'title',
        Section: 'section_table.name',
        Subsection: 'subsection_table.name',
        'Upload date': 'uploaded',
        Deadline: 'deadline',
        Status: 'status',
      },
      title: 'Filter files',
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      items: 'cae/items',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'filter_files')
    await this.reloadFiles(this.$route.params.filters)
    this.loading = false

    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'filter_files',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async reloadFiles(filters) {
      this.loading = true
      this.$store.dispatch('modals/fetchUserFields', 'filter_files')
      await this.$store.dispatch('cae/filterFiles', { ...filters, view_archived: 'all' })
      this.loading = false
    },
    openFilters() {
      this.$store.dispatch('modals/toggleConvoFilters', true)
    },
  },
}
</script>
